<template>
    <div class="singleTemplate">
        <section class="toolbar noprint">
            <div class="report--id">Case ID : {{ getCaseId }}</div>
            <div class="template--type" v-if="!autoSavingDocument">
                {{ selectedTemplateName }}
            </div>
            <div class="loader" v-if="autoSavingDocument">
                Loading...
            </div>
            <!-- <neo-button
        label="Case Report"
        bg="var(--brand-color)"
        hoverBg="var(--brand-color)"
        color="white"
        padding=" 0.5rem 1.25rem"
        style="margin-right: 20px"
        hoverText="white"
        v-if="caseReport"
        @click="saveCaseReport(REPORT_SAVE_TYPES.FINAL_REPORT)"
      ></neo-button> -->

            <neo-button label="Save Report" bg="var(--brand-color)" hoverBg="var(--brand-color)" color="white" padding=" 0.5rem 1.25rem" style="margin-right: 5rem" hoverText="white" @click="$modal.show('reportName')"></neo-button>
        </section>
        <section class="main--wrapper">
            <div class="document--editor--expand" v-if="expandHistorySidebar == false">
                <img src="../../assets/icons/ico-restore.svg" width="20" height="20" alt="ico" @click="expandHistorySidebar = true" />
            </div>
            <div class="saved--history--sidebar noprint" v-if="expandHistorySidebar == true">
                <div class="loader" v-if="!savedDocuments.docs"></div>
                <span @click="expandHistorySidebar = false"> <img src="../../assets/icons/ico-caret-right-blue.svg" width="20" height="20" alt="ico" class="document--editor--collapse"/></span>
                <ul class="saved--docs--link">
                    <a
                        ><li v-for="(link, index) in savedDocuments.docs" :key="index">
                            {{ link.timestamp }}
                            <img @click="selectedTemplate.html = link.case_report" src="../../assets/icons/ico-restore.svg" width="20" height="20" alt="ico" style="float: right; cursor: pointer" /></li
                    ></a>
                </ul>
            </div>

            <div class="document--area">
                <NeoEditorToolBar :editor="editor" :filesave="false" />
                <div v-html="getSelectedTemplateHtml + printMedia + fixFooterCss + resetEditorCss" class="template_page"></div>
            </div>
        </section>
        <modal name="reportName" @before-open="handleBeforeOpen">
            <div class="heading">
                <span class="label">Report Name</span>
            </div>
            <div class="inputs">
                <label class="inputLabel">Report Name:</label>
                <neo-input :changeCase="true" v-model="reportName" :shadow="false" placeholder="Please enter report name" borderColor="var(--brand-accent-neutral)" bg="white" margin="0.5rem 0" style='width: "100%"; border-bottom: solid 1px #e7e7e7'></neo-input>
                <span class="err" v-if="errorReportName">Report Name is a required Field</span>
            </div>
            <div class="events">
                <neo-button label="Cancel" bg="#F2F6FC" color="var(--color-danger)" hoverBg="var(--color-danger)" hoverText="white" style="display: flex; justify-content: flex-end" margin=" 0 1rem " padding="6px 10px" @click="$modal.hide('reportName')"></neo-button>
                <neo-button label="Save" bg="#F2F6FC" color="var(--brand-color)" hoverBg="var(--brand-hover-on)" hoverText="white" style="display: flex; justify-content: flex-end" margin=" 0 1rem " padding="6px 10px" @click="saveCaseReport(REPORT_SAVE_TYPES.FINAL_REPORT)"></neo-button>
            </div>
        </modal>
        <div id="dragger_elem" v-if="isLoading">
            <b-icon icon="arrows-move" font-scale="1"></b-icon>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {REPORT_SAVE_TYPES} from "@/utils/constants";
import axios from "@/axios";
import Button from "@/components/button";
import CustomInput from "@/components/input";
import Editor from "./editor.js";
import {Sketch} from "vue-color";
import NeoEditorToolBar from "./toolbar";
import {DiffDOM, nodeToObj} from "diff-dom";

export default {
    name: "SingleTemplate",
    data() {
        return {
            elementA: "",
            elementB: "",
            undoStates: [],
            redoStates: [],
            ctrlDown: false,
            shiftDown: false,
            zDown: false,
            updateDOM: null,
            expandHistorySidebar: false,
            finalReports: [],
            selectedTemplate: {},
            printMedia: ``,
            fixFooterCss: `<style> .footer{ opacity:1; }</style>`,
            resetEditorCss: `<style>
                            #editor        {background:#383838;} 
                            .editor-header {opacity:1 ;}
                            .activeFooter  {border-top:none; } 
                      </style>`,
            autoSavingDocument: false,
            caseReport: "",
            reportName: "",
            errorReportName: false,
            settings: false,
            settingType: "none",
            savedDocuments: [],
            REPORT_SAVE_TYPES,
            editor: "",
            bgColor: "#343a40",
            tableProperties: {
                width: 100,
                border: {
                    width: 1,
                    color: "#dee2e6",
                    type: "solid",
                },
            },
            imgPath: "",
            selectedImg: "",
            iconChangeMenu: false,
            modalClass: ["editor-modal"],
            icons_list: ["cancel.svg", "check.svg", "check-mark.svg", "flag.svg", "x-mark.svg", "letter-x.svg", "checked.svg"],
            icons: [],
            selectedIcon: "",
            iconBackgroundColor: "#fff",
            iconTypeRoundBackground: false,
            iconTypeSquareBackground: false,
            pageSelectedIcon: "",
            isLoading: true,
        };
    },
    components: {
        "neo-button": Button,
        "neo-input": CustomInput,
        "sketch-picker": Sketch,
        NeoEditorToolBar,
    },
    computed: {
        getCaseId: function() {
            return this.$store.getters.getCaseId;
        },

        getSelectedTemplateHtml() {
            let html = '<div data-v-618cf570="" id="editor" @keyup="keyUpEvent" @keydown="keyDownEvent" contenteditable="true" class="page" style="width: 794px;">';
            if (this.selectedTemplate.html) {
                var el = document.createElement("span");
                el.innerHTML = this.selectedTemplate.html;
                let neoPages = [...el.querySelectorAll(".neo-page")];
                neoPages.forEach((ele, index) => {
                    //Get original page Id
                    var pageId = ele.id;
                    ele.id = `page--${index + 1}`;

                    // Find contents of the page
                    var pageContents = ele.querySelectorAll(`.${pageId}`);

                    // Change Content classes to the new pageId.
                    pageContents.forEach((item) => {
                        item.classList.toggle(pageId, false);
                        item.classList.add(ele.id);
                    });

                    html += ele.outerHTML;
                });
            }
            html += "</div>";
            return html;
        },
        ...mapGetters(["reportTemplates"]),
        selectedTemplateName() {
            return this.selectedTemplate.name?.toUpperCase();
        },
    },
    methods: {
        keyUpEvent(e) {
            if (e.keyCode === 17 || e.keyCode === 91) this.ctrlDown = false;
            if (e.keyCode === 16) this.shiftDown = false;
            if (e.keyCode === 90) {
                if (this.shiftDown && this.ctrlDown && this.redoStates.length) {
                    this.redo();
                } else if (this.ctrlDown && this.undoStates.length) {
                    this.undo();
                }
                this.zDown = false;
            }
        },
        keyDownEvent(e) {
            if (e.keyCode === 17 || e.keyCode === 91) this.ctrlDown = true;
            if (e.keyCode === 16) this.shiftDown = true;
            if (e.keyCode === 90) {
                this.zDown = true;
                if (this.shiftDown && this.ctrlDown) {
                    e.preventDefault();
                    e.stopPropagation();
                    e.cancelBubble = true;
                } else if (this.ctrlDown) {
                    e.preventDefault();
                    e.stopPropagation();
                    e.cancelBubble = true;
                }
            }
        },
        updateDomDiff() {
            clearInterval(this.updateDOM);
            const dd = new DiffDOM();
            this.elementB = nodeToObj(document.getElementById("editor"));
            let diff = dd.diff(this.elementA, this.elementB);
            if (diff && diff.length) {
                this.undoStates.push(diff);
                this.elementA = nodeToObj(document.getElementById("editor"));
            }
            this.updateDOM = setInterval(this.updateDomDiff, 2000);
        },
        undo() {
            let element = this.undoStates.pop();
            const dd = new DiffDOM();
            dd.undo(document.getElementById("editor"), element);
            this.redoStates.push(element);
            this.elementA = nodeToObj(document.getElementById("editor"));
        },
        redo() {
            let element = this.redoStates.pop();
            const dd = new DiffDOM();
            dd.undo(document.getElementById("editor"), element);
            this.undoStates.push(element);
            this.elementA = nodeToObj(document.getElementById("editor"));
        },
        attachRemovableOptionToPage(page) {
            this.editor.attachRemovableOptionToPage(page);
        },

        async fetchReportOfCase() {
            let url = `/reports/case-reports/${this.getCaseId ? this.getCaseId : "ORCA-b819c2a7ec99"}?report_type=${REPORT_SAVE_TYPES.FINAL_REPORT}`;
            await axios.get(url).then((response) => {
                if (response.data.docs.length > 0) {
                    this.caseReport = response.data.docs[0].case_report;
                    this.finalReports = response.data.docs.map((n) => ({
                        id: n.id,
                        html: n.case_report,
                        name: n.name,
                    }));
                }
            });
        },
        // handleClick: function (e) {
        //   let that = this;
        //   let selection = window.getSelection
        //     ? window.getSelection()
        //     : document.selection.createRange().text;
        //   this.iconChangeMenu = false;
        //   if (event.target.tagName == "IMG") {
        //     that.settings = true;
        //     that.selectedImg = event.target;
        //     that.settingType = "image";
        //   } else if (selection.type != "None") {
        //     that.settings = true;
        //     that.settingType = "text";
        //     // if(selection.baseNode!=null){
        //     //   let selectedElem = selection.baseNode.parentNode;
        //     // }
        //     if (event.target.tagName === "path") {
        //       this.iconChangeMenu = true;
        //       this.pageSelectedIcon = e.target.parentNode.closest("svg");
        //     }
        //   }
        // },
        handleBeforeOpen() {
            this.reportName = "";
            this.errorReportName = false;
        },
        clickContextItem: function(e) {
            let clicked = e.target.getAttribute("value");
            this.editor.clickContextItem(e, clicked);
        },
        clickContextItemChild: function(e) {
            this.editor.clickContextItemChild(e);
        },
        async saveCaseReport(reportType) {
            // if (this.reportName) {
            this.autoSavingDocument = true;
            let url = `/reports/case-reports/${this.getCaseId ? this.getCaseId : "ORCA-b819c2a7ec99"}`;
            let editor = document.querySelectorAll("#editor");
            let html = "";
            editor.forEach((ele) => {
                html += ele.outerHTML;
            });

            let data = {
                report: html,
                name: reportType === REPORT_SAVE_TYPES.AUTO_SAVED_REPORT ? this.selectedTemplate.name : this.reportName,
                report_type: reportType,
            };

            if (reportType === REPORT_SAVE_TYPES.FINAL_REPORT && !this.reportName) {
                this.errorReportName = true;
                return;
            }

            if (this.selectedTemplate.name && this.selectedTemplate.html) {
                await axios.post(url, data).then(async (response) => {
                    if (response.status == 200) {
                        if (reportType === REPORT_SAVE_TYPES.FINAL_REPORT) {
                            this.$toast.success("Report Saved");
                            await this.fetchReportOfCase();
                            this.$modal.hide("reportName");
                        }
                        if (reportType === REPORT_SAVE_TYPES.AUTO_SAVED_REPORT) {
                            setTimeout(() => (this.autoSavingDocument = false), 2000);
                        }
                    }
                });
            }
            // } else {
            //   this.errorReportName = true;
            // }
        },

        async fetchAutoSavedDocs() {
            let url = `/reports/case-reports/${this.getCaseId ? this.getCaseId : "ORCA-b819c2a7ec99"}?report_type=${REPORT_SAVE_TYPES.AUTO_SAVED_REPORT}`;
            await axios.get(url).then((response) => {
                this.savedDocuments = response.data;
            });
        },
    },
    created() {
        // this.editor = new Editor("editor", {
        //   dragger: false,
        //   ui: false,
        // });
        // this.editor.init();
        // this.editor.reattachEvents({dragger:false});
    },
    async mounted() {
        document.addEventListener("page-created", (evt) => {
            this.attachRemovableOptionToPage(evt.detail.page);
        });
        const loading = this.$loading.show();
        const {hash, params} = this.$route;
        // this.loadIcons();
        if (hash == "#templates") {
            await this.$store.dispatch("fetchAllReportTemplates");
            this.selectedTemplate = this.reportTemplates.find((n) => n.id === params.tempId);
        } else {
            await this.fetchReportOfCase();
            this.selectedTemplate = this.finalReports.find((n) => n.id === params.tempId);
        }
        loading.hide();
        setTimeout(() => {
            this.editor = new Editor("editor", {
                dragger: true,
                ui: false,
                headersFootersAllowEdit: false,
            });

            let that = this;
            document.getElementById("editor").onkeydown = function(e) {
                that.keyDownEvent(e);
            };
            document.getElementById("editor").onkeyup = function(e) {
                that.keyUpEvent(e);
            };

            that.updateDOM = setInterval(() => {
                that.elementA = nodeToObj(document.getElementById("editor"));
                that.updateDomDiff();
            }, 2000);

            // this.editor.init();
            // this.editor.reattachEvents({
            //   dragger: false,
            //   headersFootersAllowEdit: false,
            // });
            this.isLoading = false;
            this.editor.fixConstraints();
            this.editor.reattachIconEvents();
            var pages = [...document.querySelectorAll(".neo-page")];
            pages.forEach((page) => {
                this.attachRemovableOptionToPage(page);
            });
        }, 2000);
        setInterval(async () => {
            await this.saveCaseReport(REPORT_SAVE_TYPES.AUTO_SAVED_REPORT);
            await this.fetchAutoSavedDocs();
        }, 10000);
        // window.addEventListener("keydown", this.handleKeydown.bind(this));
        // if(this)
        // document
        //   .getElementsByClassName("template_section")[0]
        //   .classList.add("make_collapse");
        // this.showFinalTemplateEditor = true;
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    @import "~bootstrap/scss/bootstrap.scss";
    @import "~bootstrap-vue/src/index.scss";
}
.template--type {
    font-weight: bold;
    font-size: 1.2em;
}
* {
    transition: 0.2s all !important;
}
.container {
    position: relative;
}
.wrapper {
    // min-height: 100vh;
    background-color: cyan;
}
.selection_temp {
    display: none;
}
.case_input {
    margin-bottom: 20px;
    border: 0px;
    width: 60%;
    height: 45px;
    border-radius: 5px;
    padding: 10px 20px;
    outline: none;
    left: 0;
    top: 0;
    font-weight: 900;
    font-size: 20px;
    color: #044052;
}
.template_section {
    background-color: #f1f3f4;
    padding: 30px;
    display: flex;
    flex-direction: row-reverse;
    min-height: 100vh;
    /* height : 400px */
}
.template_wrapper {
    display: block;
    /* height: 200px; */
}
.reports_templates {
    overflow: auto;
    margin-left: 158px;
}
.reports_templates .report {
    float: left;
    display: inline-block;
}
.report .img {
    margin-bottom: 10px;
    height: 183px;
    border: 1px solid #dadce0;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    background-size: 32%;
    background-repeat: no-repeat;
    background-color: #fff;
    background-position: center 55px;
    display: flex;
    flex-direction: column-reverse;
}
.report .img:hover {
    border: 1px solid rgb(21, 155, 216);
}
.report h3 {
    font-size: 12px;
    font-weight: bold;
    padding-left: 3px;
    text-align: center;
    background: #f0f3f4;
    margin: 0;
    padding: 10px 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.report {
    width: 144px;
    margin-right: 15px;
}
.make_collapse .template_wrapper {
    display: none;
}
.make_collapse {
    height: 80px;
}
.make_collapse .case_input {
    position: absolute;
    top: -13px;
    left: 185px;
}
.make_collapse .selection_temp {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
}
.template_page {
    box-shadow: rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    margin-top: 20px;
    margin-bottom: 20px;
    background: #fff;
    // width: 60%;
    width: 794px;
    margin: 0 auto;
    padding-top: 3px;
    margin-top: 15rem;
}
#loading-template {
    height: 183px;
    width: calc(100% - 165px);
    position: relative;
    margin-right: 0;
}

.loader,
.loader:before,
.loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
}
.loader {
    position: absolute;
    margin-left: 50%;
    color: #216ad4;
    font-size: 5px;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
.loader:before,
.loader:after {
    content: "";
    position: absolute;
    top: 0;
}
.loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.loader:after {
    left: 3.5em;
}
@-webkit-keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}
@keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

.toolbar {
    // background:cyan;
    background: whitesmoke;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    position: fixed;
    z-index: 998;
    .report--id {
        margin-left: 20px;
        font-size: x-large;
        font-weight: initial;
        color: #216ad4;
    }
}

.document--version {
    background: cyan;
}

.main--wrapper {
    // background:cyan;
    height: 100vh;
    display: flex;

    background: #383838;
    // background:#ff1313;

    .document--editor--expand {
        background: whitesmoke;
        padding: 10px 10px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        height: 46px;
        cursor: pointer;
        margin-top: 20px;
        position: fixed;
        z-index: 1000;
        top: 9rem;
    }
    .saved--history--sidebar {
        margin-top: 0px;

        flex: 2;
        background: white;
        padding-top: 60px;
        position: relative;

        position: fixed;
        height: 100vh;
        width: 400px;
        z-index: 1000;
        .saved--docs--link {
            list-style-type: none;
            color: dodgerblue;
            width: 80%;

            li {
                margin-top: 10px;
            }
        }

        .document--editor--collapse {
            position: absolute;
            top: 10px;
            right: 10px;

            cursor: pointer;
            -webkit-transform: scaleX(-1);
        }
    }
    .document--area {
        // background:pink;
        // background:#525659;
        // background:#33a7ff;
        background: #383838;
        overflow-y: scroll;
        flex: 10;
    }
}

#contextMenuContainer {
    display: none;
    position: fixed;
    background: #3e3d3d;
    z-index: 100000;
}
#colorContextMenu {
    display: none;
    position: fixed;
    background: var(--brand-accent-dark);
    z-index: 100000;
}
#contextMenuContainer ul {
    padding-left: 0;
    width: 150px;
    margin: 8px 0px;
}
#contextMenuContainer ul li {
    list-style-type: none;
    padding: 2px 10px;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    position: relative;
}
#contextMenuContainer ul li ul {
    position: absolute;
    display: none;
    top: 13px;
    left: 50px;
    background: #82797c;
    z-index: 100;
}
#contextMenuContainer ul li:hover ul {
    display: block;
}
#contextMenuContainer ul li:hover {
    background: #5a5959;
}
#editor {
    background: #383838 !important;
}

#editor--options--sidepanel {
    padding-left: 10px !important;
    padding-right: 10px;
    .fileSelect {
        padding: 1.5em 1em;
    }
}
.fileSelect {
    padding: 10px;
}
.fileSelect img {
    width: 100%;
}
.template_page {
    background: #383838;
}
.heading {
    padding: 10px 20px;
    display: flex;
    border-bottom: 1px solid #d6d6d6;
    letter-spacing: 1px;
    .label {
        padding: 1em 0;
        font-weight: 900;
    }
}

.inputs,
.events {
    padding: 0.9em 1em;
}

.events {
    display: flex;
    justify-content: flex-end;
}

.err {
    color: var(--color-danger);
    font-size: 0.8em;
}
.tabs {
    margin: 1em 0;
}
.header {
    display: flex;
    /* width: 100%; */
    justify-content: center;
    justify-content: space-around;
}
.icon-box {
    height: 50px;
    background: #ffffff;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 3px;
}
.icon--options {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
    input {
        margin-left: 10px;
        margin-right: 8px;
    }
}
::v-deep .tabs .tab p {
    margin: 0.5em 0;
}
@media screen and (max-width: 1839px) {
    .template_page {
        margin-top: 26em !important;
    }
}

@media screen and (max-width: 1059px) {
    .template_page {
        margin-top: 28em !important;
    }
}
.main {
    ::v-deep {
        .toolbox {
            left: initial;
            margin-left: 0;
            margin-top: 0;
            top: 11.2em;
            .list-group {
                margin-left: 2rem;
                li:nth-child(5) {
                    padding: 0;
                }
            }
        }
    }
}
</style>
